import { render, staticRenderFns } from "./partnerAccordionItem.vue?vue&type=template&id=3076a3e0&scoped=true&"
import script from "./partnerAccordionItem.vue?vue&type=script&lang=js&"
export * from "./partnerAccordionItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3076a3e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VExpandTransition,VIcon,VSheet})
