<template>

  <div>
    <page-section
        :background-color="wsLIGHTCARD"
        class="px-4"
    >
      <v-row class="py-11">
        <v-col cols="12" md="6">
          <div class="fill-height d-flex align-center">
            <img
                src="@/assets/img/partner/partner_program.png"
                width="100%"
            />
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="d-flex align-center fill-height">
            <div>
              <h1 class="wsDARKER" :style="`font-size: ${SM ? 24 : 42}px`">
                Приєднуйтесь до партнерської програми WeStudy
                та заробляйте разом з нами
              </h1>
              <h4 class="wsACCENT font-weight-regular mt-4">
                Заробляй 200% від місячної підписки обраного клієнтом тарифу протягом чотирьох місяців платного користування платформою цим клієнтом.
              </h4>
              <div class="mt-10">
                <ws-button
                    :to="localeLink('partner_program/rules')"
                    label="Читати умови"
                    class="mr-2"
                    outlined
                />
                <ws-button
                    @click="openDialog"
                    label="Стати партнером"
                />
              </div>


            </div>
          </div>

        </v-col>
      </v-row>

    </page-section>

    <page-section
        class="pt-16 px-4"
        title="Для кого це буде корисно"

    >

      <v-row class="mt-16">
        <v-col v-for="(item,i) in casesSelect" :key="i"
               cols="12" md="4" class="pb-6"
        >
          <v-sheet class="fill-height pa-6  wsRoundedHalf" style="border : 2px solid var(--wsBACKGROUND)">
            <v-icon size="40" :color="wsATTENTION">
              {{ item.icon }}
            </v-icon>
            <h3 class="wsDARKER mt-6">
              {{ $t(item.title) }}
            </h3>
            <h4 class="wsDARKER font-weight-regular mt-4" style="font-size: 16px">
              {{ $t(item.subtitle) }}
            </h4>
          </v-sheet>
        </v-col>
      </v-row>

    </page-section>

    <page-section
        title="Як це працює"
        class="py-16 px-4"
    >
      <div class="pt-6">
        <v-sheet
            v-for="(item,index) in howItWorksSelect" :key="index"
            max-width="782" class="d-flex align-center wsRoundedHalf mb-4 mx-auto pa-6" :color="wsLIGHTCARD" >


          <ws-img class="mr-4 mt-3 mb-3"  width="80" height="80"  :src="require(`@/assets/illustrations/pricing_${index + 1}.png`)" />
          <div>
            <h3 class="wsATTENTION">
              {{ item.title }}
            </h3>
            <h4 class="font-weight-regular wsACCENT mt-1">
              {{ item.subtitle }}
            </h4>
          </div>
        </v-sheet>
      </div>

    </page-section>

    <v-sheet style="position: relative" >
      <v-sheet style="position: absolute; z-index: 0; top : 0 ; left: 0; right: 0; bottom: 50%" color="transparent" ></v-sheet>
      <v-sheet style="position: absolute; z-index: 0; top : 50% ; left: 0; right: 0; bottom: 0" color="var(--wsDARKLIGHT)" ></v-sheet>

      <div class="px-4" style="z-index: 100; position: absolute; left: 0; right: 0">
        <v-sheet :color="wsATTENTION" max-width="1190" class="mx-auto pa-8 wsRoundedHalf">
          <h2 class="wsWHITE">Бонуси для приведених клієнтів</h2>
          <h4 class="wsWHITE font-weight-regular mt-1" style="max-width: 600px">
            Партнерська програма WeStudy передбачає бонусні Гб хмарного сховища для приведених Вами клієнтів
          </h4>

          <v-row class="mt-6">
            <v-col v-for="(item,index) in partnerAdvantagesSelect" :key="index"
                   cols="12" md="4"
            >
              <v-sheet class="d-flex pa-6 wsRoundedHalf justify-space-between align-center">
                <h3 :style="`font-size : ${SM ? 16 : 19}px`" class="wsDARKER">{{ item.plan }}</h3>
                <h1 :style="`font-size : ${SM ? 24 : 42}px`" class="wsDARKER" >{{ item.advantage }}</h1>
              </v-sheet>
            </v-col>

          </v-row>

        </v-sheet>
      </div>

      <div class="px-4" style="z-index: 100; opacity: 0">
        <v-sheet :color="wsATTENTION" max-width="1190" class="mx-auto pa-8 wsRoundedHalf">
          <h2 class="wsWHITE">Бонуси для приведених клієнтів</h2>
          <h4 class="wsWHITE font-weight-regular mt-1" style="max-width: 600px">
            Партнерська програма WeStudy передбачає бонусні Гб хмарного сховища для приведених Вами клієнтів
          </h4>

          <v-row class="mt-6">
            <v-col v-for="(item,index) in partnerAdvantagesSelect" :key="index"
                   cols="12" md="4"
            >
              <v-sheet class="d-flex pa-6 wsRoundedHalf justify-space-between align-center">
                <h3 :style="`font-size : ${SM ? 16 : 19}px`" class="wsDARKER">{{ item.plan }}</h3>
                <h1 :style="`font-size : ${SM ? 24 : 42}px`" class="wsDARKER" >{{ item.advantage }}</h1>
              </v-sheet>
            </v-col>

          </v-row>

        </v-sheet>
      </div>

    </v-sheet>

    <page-section
        background-color="var(--wsDARKLIGHT)"
        class="py-16 px-4"
    >

      <v-sheet
          :color="wsDARKER"
          class="wsRoundedHalf overflow-hidden d-flex align-center justify-center mx-auto"
          :height="SM ? 224 : 448"
          width="100%"
          max-width="782"
          style="position: relative"
      >
        <iframe width="100%"  :height="SM ? 224 : 448" src="https://www.youtube.com/embed/z-OAbGCvs0k?si=tyzz32QcQ0oKUU-_"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
        />
      </v-sheet>

      <h1 class="wsWHITE text-center mt-16 mb-6">Питання — відповіді</h1>

      <partnerAccordionItem
          v-for="(item,i) in faqSelect" :key="i"
          @expand="collapseTrigger++"
          :item="item"
          :collapse-trigger="collapseTrigger"
      />


    </page-section>

    <ws-dialog
        v-if="displayDialog"
        v-model="displayDialog"
        title="Подати заявку на партнерство"
        @save="addPartnerRequest"
        :loading="LOADING"
        :hide-save="!!requestSent"
        cancel-text="Закрити"
    >

      <div v-if="!requestSent">
        <v-row no-gutters>
          <v-col cols="12" md="6" :class="[{'pr-2' : !SM}]">
            <ws-text-field
                v-model="entityData.name"
                label="Імʼя"
                placeholder="Введіть ваше імʼя"
                :error="nameError"
                @input="nameError = false"
            />
          </v-col>
          <v-col cols="12" md="6" :class="[{'pl-2' : !SM},{'pt-5' : SM}]">
            <ws-text-field
                v-model="entityData.lastname"
                label="Прізвище"
                placeholder="Введіть ваше прізвище"
                :error="lastnameError"
                @input="lastnameError = false"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" :class="[{'pr-2' : !SM}]"
          >
            <ws-phone-input
                v-model="entityData.phone"
                label="Телефон"
                placeholder="Введіть актуальний номер"
                hide-details
                :error="phoneError"
                @input="phoneError = false"
            />
          </v-col>
          <v-col cols="12" md="6" :class="[{'pl-2' : !SM},{'pt-5' : SM}]">
            <ws-text-field
                v-model="entityData.email"
                label="Пошта"
                placeholder="Введіть електронну адресу"
                :error="emailError"
                @input="emailError = false"
            />
          </v-col>
        </v-row>

        <ws-select
            v-model="entityData.type"
            :items="partnerTypesSelect"
            label="Про себе"
            placeholder="Опишіть вашу діяльність"
            class="mt-5"
            :error="typeError"
            @input="typeError = false"
        />

        <ws-text-field
            v-model="entityData.comment"
            label="Коментар"
            placeholder="Додатковий коментар"
            area
        />
      </div>

      <div v-else>
        <h3 class="wsDARKER  font-weight-regular mt-5">Вашу заявку на партнерство надіслано</h3>
        <h4 class="wsACCENT mt-5 font-weight-regular ">Найближчим часом з вами звʼяжеться наш менеджер за вказаними Вами контактними даними для затвердження вашої заявки</h4>
      </div>


    </ws-dialog>

  </div>

</template>
<script>
import partnerAccordionItem from "@/components/pages/homepage_westudy/partnerProgram/partnerAccordionItem.vue";
import {mapActions} from "vuex";
export default {
  name: "HomeMainPartnerProgram",
  components : {
    partnerAccordionItem
  },
  data() {
    return {
      entityData : {},

      displayDialog : false,
      phoneError : false,
      emailError : false,
      nameError : false,
      lastnameError : false,
      typeError : false,

      requestSent : false,

      // Selectors
      partnerTypesSelect : [
        { text : 'CRM,LMS експерт' , value : 'CRM,LMS експерт' },
        { text : 'Маю власну онлайн школу' , value : 'Маю власну онлайн школу' },
        { text : 'Інфлуенсер' , value : 'Інфлуенсер' },
        { text : 'Творець контенту' , value : 'Творець контенту' },
        { text : 'Дизайнер сайтів' , value : 'Дизайнер сайтів' },
        { text : 'Професійний контент менеджер' , value : 'Професійний контент менеджер' },
        { text : 'Інше' , value : 'Інше' }
      ],
      casesSelect : [
        { title : 'Агенція',
          subtitle : 'Пропонуючи більше рішень, ви можете виділитися серед конкурентів і залучити більше клієнтів.',
          icon : 'mdi-account-multiple'
        },
        { title : 'Власник вебсайту',
          subtitle : 'Партнерська програма може допомогти монетизувати трафік шляхом залучення відвідувачів.',
          icon : 'mdi-view-carousel'
        },
        { title : 'Творець контенту',
          subtitle : 'Рекомендування нашого сервісу може підвищити інтерес аудиторії та залучення до контенту..',
          icon : 'mdi-monitor-account'
        },
        { title : 'Інфлюенсер',
          subtitle : 'Підписники отримуватимуть користь від використання рекомендованих вами продуктів, що покращить їхній досвід взаємодії з вами.',
          icon : 'mdi-earth'
        },
        { title : 'Школа інфобізнесу',
          subtitle : 'Впровадження нових технологій та сервісів через партнерські програми допомагає вам залишатися на передовій ринку освітніх послуг.',
          icon : 'mdi-school'
        },
        { title : 'Налаштовуєш сайти, CRM та LMS',
          subtitle : 'Включення нашого сервісу до послуг збільшить привабливість вашої пропозиції для клієнтів, оскільки ви пропонуєте комплексне рішення.',
          icon : 'mdi-view-quilt'
        }
      ],
      howItWorksSelect : [
        { title : 'Зареєструйтесь як партнер',
          subtitle : 'Створіть особистий профіль, щоб розпочати роботу.',
          img : 'mdi-school'
        },
        { title : 'Отримайте реферальне посилання',
          subtitle : 'В кабінеті партнера Ви автоматично отримаєте посилання, яким зможете ділитися.',
          img : 'mdi-school'
        },
        { title : 'Отримайте заробіток',
          subtitle : 'Отримуйте кошти залежно від кількості приведених клієнтів та сплачених ними тарифів.',
          img : 'mdi-school'
        }
      ],
      partnerAdvantagesSelect : [
        { plan : "Базовий тариф",
          advantage : "+10Гб"
        },
        { plan : "Елітний тариф",
          advantage : "+25Гб"
        },
        { plan : "Елітний+ тариф",
          advantage : "+50Гб"
        }
      ],
      collapseTrigger : 0,
      faqSelect : [
        { title : "Яким чином фіксується партнерська комісія?",
          content : "Комісія фіксується після оплати залученим Вами клієнтом. Нарахування відбувається за перші 4 місяці користування клієнтом платформи WeStudy."
        },
        { title : "Коли я отримаю гроші?",
          content : "Перша виплата стане доступною через місяць з моменту першого платежу Вашого клієнта, та за умови залучення двох чи більше клієнтів, що сплатили за користування платформою WeStudy. <br><br>" +
              "\n" +
              "Замовити виплату Ви можете натиснувши на відповідну кнопку в кабінеті партнера \"Отримати виплату\". Після замовлення, ми виплатимо гроші протягом трьох робочих днів в зручний для Вас спосіб. "
        },
        { title : "Які бонуси є для приведених мною клієнтів?",
          content : "Партнерська програма WeStudy передбачає бонусні Гб хмарного сховища для приведених Вами клієнтів. Залежно від обраного тарифу, клієнт отримає наступні бонуси:\n" +
              "<br><br>Базовий тариф: + 10 Гб \n" +
              "<br>Елітний тариф: + 25 Гб\n" +
              "<br>Елітний+ тариф: + 50 Гб"
        },
        { title : "Скільки я можу заробити?",
          content : "Обмежень на кількість приведених клієнтів не існує! Загальний обсяг нарахування коштів складає 200 % від обраного та сплаченого місячного тарифу приведеного Вами Клієнта протягом 4 місяців. Таким чином, за 1 залученого клієнта Ви отримаєте наступну винагороду відповідно до обраного клієнтом тарифу:" +
              "<br>Базовий: 22 дол. США" +
              "<br>Елітний: 74 дол. США" +
              "<br>Елітний+: 158 дол. США" +
              "<br><br>Як приклад: якщо Ви протягом місяця привели 10 клієнтів у кожний з тарифних планів, то Ваша фінальна винагорода складатиме 2540 дол. США",
          expanded : true
        },
      ],

    }
  },
  methods : {
    ...mapActions('adminPartners' , ["ADD_PARTNER_REQUEST"]),

    openDialog() {
      this.phoneError = false
      this.emailError = false
      this.nameError = false
      this.lastnameError = false
      this.typeError = false
      this.requestSent = false

      this.entityData = {}
      this.displayDialog = true
    },
    async addPartnerRequest() {
      if (!this.entityData.phone) {
        this.phoneError = true
        return this.notify('Вкажіть ваш номер телефону' , 'warning')
      }
      if (!this.entityData.email) {
        this.emailError = true
        return this.notify('Вкажіть вашу пошту' , 'warning')
      }

      if (!this.VALIDATE_EMAIL(this.entityData.email)) {
        this.emailError = true
        return this.notify('Не правильний формат пошти' , 'warning')
      }

      if (!this.entityData.name) {
        this.nameError = true
        return this.notify('Вкажіть ваше імєʼя' , 'warning')
      }
      if (!this.entityData.lastname) {
        this.lastnameError = true
        return this.notify('Вкажіть ваше прізвище' , 'warning')
      }
      if (!this.entityData.type) {
        this.typeError = true
        return this.notify('Вкажіть інформацію про себе' , 'warning')
      }

      let result = await this.ADD_PARTNER_REQUEST(this.entityData)
      if (!result) {
        this.ERROR()
      }
      this.notify('Заявку надіслано' , 'success')
      this.requestSent = true
    }
  }
}
</script>

<style scoped>

</style>